
import { defineComponent, onMounted } from "vue";
import BaseService from "@/view/pages/city/services/BaseService.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

import { API, Storage, graphqlOperation } from "aws-amplify";
import { listPageTables } from "@/graphql/queries";

export default defineComponent({
  name: "explorar-museus-poa",
  components: {
    BaseService,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Caldeira Cloud", "Museus");
    });
  },
  data() {
    return {
      pageId: "poacity_museum_pagetable",
      collection: {
        banner: "",
        bannerSmall: "",
        title: undefined,
        shortDescription: undefined,
        description: undefined,
        description1: undefined,
        description2: undefined,
        searchPlaceholder: undefined,
        pageBreadcrumbs: "",
        metadata: {
          featureCollection: [],
          breadcrumbs: undefined,
        },
      },
    };
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      try {
        const response: any = await API.graphql(
          graphqlOperation(listPageTables, {
            filter: {
              pageId: {
                eq: this.pageId,
              },
              enabled: {
                eq: true,
              },
            },
          })
        );

        if (response.data.listPageTables.items.length > 0) {
          this.collection = response.data.listPageTables.items[0];
          if (this.collection) {
            const banner = await Storage.get(this.collection.banner, {
              level: "public",
            });
            this.collection.banner = banner;

            const bannerSmall = await Storage.get(this.collection.bannerSmall, {
              level: "public",
            });
            this.collection.bannerSmall = bannerSmall;

            let metadata = JSON.parse(
              response.data.listPageTables.items[0].metadata
            );
            this.collection.metadata = metadata;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
});
