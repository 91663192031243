import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_service = _resolveComponent("base-service")!

  return (_ctx.collection)
    ? (_openBlock(), _createBlock(_component_base_service, {
        key: 0,
        "header-banner": _ctx.collection.banner,
        title: _ctx.collection.title,
        subtitle: _ctx.collection.shortDescription,
        description: _ctx.collection.description,
        description1: _ctx.collection.description1,
        description2: _ctx.collection.description2,
        "is-searchable": false,
        "search-placeholder": _ctx.collection.searchPlaceholder,
        collection: _ctx.collection.metadata.featureCollection,
        breadcrumbs: _ctx.collection.metadata.breadcrumbs
      }, null, 8, ["header-banner", "title", "subtitle", "description", "description1", "description2", "search-placeholder", "collection", "breadcrumbs"]))
    : _createCommentVNode("", true)
}